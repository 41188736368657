.search-container {
  position: relative;
  display: flex;
  align-items: center;
}
.receipt {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  z-index: 1000;
}
.table-bordered tbody tr td.dsb {
  /* Estilos desejados para o <td> com disabled="true" */

  filter: brightness(0.8);
  border: 0;

  pointer-events: none;
}

.search-input {
  padding: 10px 10px 10px 30px; /* Espaço para o ícone à esquerda */
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 10px; /* Posição do ícone dentro do campo */
  top: 50%; /* Centraliza verticalmente */
  transform: translateY(-50%); /* Ajuste para alinhar corretamente */
  cursor: pointer;
  opacity: 0.6; /* Para um efeito mais suave */
}
.center-title {
  text-align: center; /* Centraliza o texto dentro do h2 */
  justify-content: center; /* Centraliza horizontalmente */
  height: 5vh; /* Faz a body ocupar toda a altura da janela */
}

.search-container svg {
  position: relative;
  left: -30px;
}

.modal-fotter {
  display: flex;
  justify-content: end;
  border-top: solid 1px rgb(0, 0, 0, 0.2);
}

.modal-fotter button {
  margin: 10px;
}

.p404 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  text-align: center;
}

.p404 img {
  position: relative;
  margin-top: 20px;
  width: 35%;
}

.sidebar a.active {
  background: #198754;
}
.area-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.loader {
  border: 3px solid #f3f3f3; /* Cor clara para o fundo do loader */
  border-top: 3px solid #005f87; /* Cor do loader */
  border-radius: 50%; /* Faz um círculo */
  width: 50px; /* Largura do loader */
  height: 50px; /* Altura do loader */
  animation: spin 0.5s linear infinite; /* Animação de rotação */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gmRYUp .card-footer {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
}

.arvore-l thead th {
  text-align: center;
}

.arvore-l tbody tr {
  text-align: center; /* Alinha o texto horizontalmente */
  vertical-align: middle; /* Alinha o texto verticalmente */
}

td.btns button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

td.btns button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

a.load,
a.load:hover,
button.load,
button.load:hover {
  background-color: #dadee0 !important;
  border: #dadee0 !important;
  color: #dadee0 !important;
  cursor: wait;
}
a.load,
button.load {
  background: #dadee0;
  background-color: rgb(218, 222, 224);
  border: solid #dadee0;
  position: relative;
}
button.load::after {
  content: "";
  position: absolute;
  background-image: url("https://cdn.rifei.co/storage/images/icons/dark-spinner.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 22px;
  width: 22px;
  height: 22px;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.b-wpp {
  display: flex;
  align-items: center;
  background: #43d859;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  border: solid 1px rgb(0, 0, 0, 0.2);
}

.b-wpp svg {
  position: relative;
  margin-right: 3px;
  color: #fff;
  width: 18px;
  height: 18px;
}
.b-wpp:hover {
  background: #3aaf4e;
  color: #fff;
}

.b-wpp:focus {
  background: #3aaf4e !important;
  color: #fff !important;
  border: solid 1px rgb(0, 0, 0, 0.2);
}
.sidebar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #005f87;
  z-index: 1000;
}
.custom-upl {
  position: relative;
}
.custom-upl input {
  position: absolute;
  opacity: 0;
  height: 38px;
  width: 180px;
  cursor: pointer;
}
[tooltip] {
  position: relative;
  cursor: pointer;
}

[tooltip]::before {
  content: attr(tooltip);
  position: absolute;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

[tooltip]:hover::before {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-5px);
}
